import React from "react";
import { withAuthenticator, Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import {
  Button,
  Header,
  Label,
  Table,
  Grid,
  Message,
  Icon,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import BaseLayout from "../../components/templates/BaseLayout";
import { allSupportConversations } from "../../graphql/queries";
import { BreadcrumbExampleShorthand } from "../../components/molecules/functions";

const moment = require("moment");

const TableView = ({ conversations }) => (
  <Table basic="very" celled collapsing>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>User</Table.HeaderCell>
        <Table.HeaderCell>User Type</Table.HeaderCell>
        <Table.HeaderCell>Date reported</Table.HeaderCell>
        <Table.HeaderCell>Email</Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <RowContent conversations={conversations} />
  </Table>
);

const RowContent = ({ conversations }) => (
  <Table.Body>
    {conversations.map((conversation) => {
      if (!conversation.user) return null;

      const {
        id,
        createdAt,
        user: { cognitoId, firstName, lastName, email, userType },
        messageCount,
        responded,
      } = conversation;

      if (messageCount === 0) return null;

      return (
        <Table.Row key={id}>
          <Table.Cell>
            <Label
              as="a"
              href={
                userType === "Customers"
                  ? `/customer/${cognitoId}?url=/supportchats&name=Support Chats`
                  : `/maid/${cognitoId}?url=/supportchats&name=Support Chats`
              }
              content={`${firstName} ${lastName || ""}`}
            />
            {!responded && (
              <img
                src="/images/dot.gif"
                alt="Unread"
                height="30"
                style={{ marginLeft: `${-15}px`, verticalAlign: "bottom" }}
              />
            )}
          </Table.Cell>
          <Table.Cell>
            {userType === "Customers" ? "Client" : "Maid"}
          </Table.Cell>
          <Table.Cell>
            {moment(createdAt, "x").format("MMMM Do YYYY, h:mm:ss a")}
          </Table.Cell>
          <Table.Cell>
            <a href={`mailto:${email || ""}`}>{email || ""}</a>{" "}
          </Table.Cell>
          <Table.Cell>
            <Button
              color="blue"
              as={Link}
              to={`/conversation/${id}?s=1&url=/supportchats&name=Support Chats`}
            >
              View
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    })}
  </Table.Body>
);

const SupportChats = () => {
  const AllSupportConversations = () => {
    return (
      <Connect query={graphqlOperation(allSupportConversations)}>
        {({ data: { listAllMaidSupportConversations }, loading, error }) => {
          if (loading || !listAllMaidSupportConversations)
            return (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  Processing your request.
                </Message.Content>
              </Message>
            );
          listAllMaidSupportConversations.forEach(
            (conv) =>
              (conv.responded =
                conv.messages &&
                conv.messages.some((x) => x.sender !== conv.id))
          );
          return (
            <TableView
              conversations={listAllMaidSupportConversations.sort((a, b) =>
                !a.responded && b.responded
                  ? -1
                  : a.responded && !b.responded
                  ? 1
                  : b.createdAt - a.createdAt
              )}
            />
          );
        }}
      </Connect>
    );
  };

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    { key: "supportchat", content: "Support Chats", active: true },
  ];

  return (
    <BaseLayout>
      <Grid divided>
        <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">Support Chats</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <AllSupportConversations />
    </BaseLayout>
  );
};

export default withAuthenticator(SupportChats);
