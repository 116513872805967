import React, { useState } from "react";
import { withAuthenticator, Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import {
  Header,
  Label,
  Table,
  Grid,
  Message,
  Icon,
  Form,
} from "semantic-ui-react";
import BaseLayout from "../../components/templates/BaseLayout";
import DropdownList from "../../components/atoms/DropdownList";
import { prospectsByLocale } from "../../graphql/queries";
import countries from "../../constants/countries";

import "./styles.css";
import { BreadcrumbExampleShorthand } from "../../components/molecules/functions";

const moment = require("moment");

const TableView = ({ prospects }) => (
  <Table basic="very" celled collapsing>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Postcode</Table.HeaderCell>
        <Table.HeaderCell>Number</Table.HeaderCell>
        <Table.HeaderCell>Clean Type</Table.HeaderCell>
        <Table.HeaderCell>Frequency</Table.HeaderCell>
        <Table.HeaderCell>Property</Table.HeaderCell>
        <Table.HeaderCell>Bedroom</Table.HeaderCell>
        <Table.HeaderCell>Bathroom</Table.HeaderCell>
        <Table.HeaderCell>Date Created</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <RowContent prospects={prospects} />
  </Table>
);

const RowContent = ({ prospects }) => (
  <Table.Body>
    {prospects.map((prospect) => {
      const {
        id,
        postcode,
        number,
        dateCreated,
        geoJson,
        cleanType,
        frequency,
        bathroom,
        bedroom,
        property,
      } = prospect;

      const geoParsed = JSON.parse(geoJson);
      const coordinates = `${geoParsed.latitude}%2C${geoParsed.longitude}`;
      const mapLink = `https://maps.google.com/?q=${coordinates}&z=16`;
      return (
        <Table.Row key={id}>
          <Table.Cell>
            <Header as="h4">
              <Header.Content>
                {postcode}
                <Header.Subheader>
                  <Label
                    as="a"
                    href={mapLink}
                    target="_blank"
                    content="Map"
                    icon="map"
                    className="mapLink"
                  />
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Table.Cell>
          <Table.Cell>{number}</Table.Cell>
          <Table.Cell>{cleanType}</Table.Cell>
          <Table.Cell>{frequency}</Table.Cell>
          <Table.Cell>{property}</Table.Cell>
          <Table.Cell>{bedroom}</Table.Cell>
          <Table.Cell>{bathroom}</Table.Cell>
          <Table.Cell>
            {moment(dateCreated, "x").format("DD ddd MMM YYYY HH:mm")}
          </Table.Cell>
        </Table.Row>
      );
    })}
  </Table.Body>
);

const Prospects = () => {
  const [locale, setLocale] = useState("en-GB");

  const AllProspects = () => {
    return (
      <Connect query={graphqlOperation(prospectsByLocale, { locale })}>
        {({ data: { listProspectsByLocale }, loading }) => {
          if (loading || !listProspectsByLocale)
            return (
              <Message icon>
                <Icon name="circle notched" loading />
                <Message.Content>
                  <Message.Header>Just one second</Message.Header>
                  Processing your request.
                </Message.Content>
              </Message>
            );

          return listProspectsByLocale.length > 0 ? (
            <TableView prospects={listProspectsByLocale} />
          ) : (
            <h4 className="text-danger">No matching records found</h4>
          );
        }}
      </Connect>
    );
  };

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    { key: "prospects", content: "Prospects", active: true },
  ];

  return (
    <BaseLayout>
      <Grid divided>
      <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2">Prospects</Header>
          </Grid.Column>
        </Grid.Row>
        <div className="justify-content-between card-item search-info">
          <Form className="w-100">
            <Form.Select
              className="country-filter"
              label="Country"
              placeholder="Select country"
              options={countries}
              value={locale}
              onChange={(event, { value }) => setLocale(value)}
            />
          </Form>
        </div>
        <Grid.Row></Grid.Row>
      </Grid>
      <AllProspects />
    </BaseLayout>
  );
};

export default withAuthenticator(Prospects);
