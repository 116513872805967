import React, { useState } from "react";
import { Header, Button, Grid, Form } from "semantic-ui-react";

const SearchBox = ({ onSearch, onReset }) => {
  const [filter, setFilter] = useState({
    company: "",
    email: "",
    name: "",
  });

  return (
    <>
      <Form.Input
        label="Search by company"
        placeholder="Company name..."
        value={filter.company || ""}
        onChange={(e) => setFilter({ ...filter, company: e.target.value })}
      />
      <Form.Input
        label="Search by maid"
        placeholder="Maid name..."
        value={filter.name || ""}
        onChange={(e) => setFilter({ ...filter, name: e.target.value })}
      />

      <Form.Input
        label="Search by email"
        placeholder="Maid name..."
        value={filter.email || ""}
        onChange={(e) => setFilter({ ...filter, email: e.target.value })}
      />
      <Button
        color="green"
        onClick={() => {
          const { email, company, name } = filter;

          if (email.length === 0 && company.length === 0 && name.length === 0) {
            onSearch(null);
          } else {
            const f = {};

            if (email.length > 0) f.email = { contains: email };
            if (company.length > 0) f.companyName = { contains: company };
            if (name.length > 0) f.fullName = { contains: name };
            onSearch(f);
          }
        }}
      >
        Search
      </Button>
      <Button
        color="red"
        onClick={() => {
          setFilter({ email: "", company: "", name: "" });
          onReset();
        }}
      >
        Reset
      </Button>
    </>
    // <Grid.Column width={12}>
    //   <Grid.Row>
    //     <Grid.Column>
    //       <Header as="h3" content="Search" />
    //       <input
    //         className="common-search"
    //         onChange={(e) => setFilter({ ...filter, company: e.target.value })}
    //         value={filter.company || ""}
    //         placeholder="Company name"
    //       />
    //       <input
    //         className="common-search"
    //         onChange={(e) =>
    //           setFilter({ ...filter, name: e.target.value.toLowerCase() })
    //         }
    //         value={filter.name || ""}
    //         placeholder="Maid name"
    //       />
    //       <input
    //         className="common-search"
    //         onChange={(e) => setFilter({ ...filter, email: e.target.value })}
    //         value={filter.email || ""}
    //         placeholder="Email"
    //       />
    //     </Grid.Column>
    //   </Grid.Row>
    //   <Grid.Row>
    //     <Grid.Column>
    //       <Button
    //         color="green"
    //         onClick={() => {
    //           setFilter({ email: "", company: "", name: "" });
    //           onReset();
    //         }}
    //       >
    //         Reset
    //       </Button>
    //       <Button
    //         color="green"
    //         onClick={() => {
    //           const { email, company, name } = filter;

    //           if (
    //             email.length === 0 &&
    //             company.length === 0 &&
    //             name.length === 0
    //           ) {
    //             onSearch(null);
    //           } else {
    //             const f = {};

    //             if (email.length > 0) f.email = { contains: email };
    //             if (company.length > 0) f.companyName = { contains: company };
    //             if (name.length > 0) f.fullName = { contains: name };
    //             onSearch(f);
    //           }
    //         }}
    //       >
    //         Search
    //       </Button>
    //     </Grid.Column>
    //   </Grid.Row>
    // </Grid.Column>
  );
};

export default SearchBox;
