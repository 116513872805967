import React, { useState } from "react";
import BaseLayout from "../../components/templates/BaseLayout";
import "./styles.css";
import CustomDropdown from "../../components/atoms/DropdownList/customSelect";
import {
  Form,
  Grid,
  Header,
  TextArea,
  Icon,
  Input,
  Button,
  Label,
} from "semantic-ui-react";
import DropdownList from "../../components/atoms/DropdownList";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_GATEWAY } from "../../constants/Urls";
import { Auth } from "aws-amplify";
import axios from "axios";
import { BreadcrumbExampleShorthand } from "../../components/molecules/functions";

const PushNotifications = () => {
  const [selectedUserType, setSelectedUserType] = useState("Maids");
  const [selectedCategory, setSelectedCategory] = useState("Active");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [message, setMessage] = useState("");
  const [selectedDeepLinkOption, setSelectedDeepLinkOption] = useState({
    label: "Homepage",
    value: "",
  });
  const [isConfirmPopup, setIsConfirmPopup] = useState(false);
  const [isNotificaitonSending, setIsNotificaitonSending] = useState(false);
  const [sentNotificationCount, setSentNotificationCount] = useState(0);
  const [isSuccessNotifiedPopup, setIsSuccessNotifiedPopup] = useState(false);

  const isBtnDisabled = !selectedUserType && !selectedCategory;

  const userOptions = [
    {
      text: "Maids",
      value: "Maids",
    },
    {
      text: "Clients",
      value: "Clients",
    },
  ];
  const categoryOptions = [
    {
      text: "Active",
      value: "Active",
    },
  ];

  const handleConfirmPopup = () => {
    return (
      <SweetAlert
        warning
        showCancel
        title={`Are you sure to send notification to ${selectedCategory.toLowerCase()} ${selectedUserType.toLowerCase()} ?`}
        onConfirm={() => handleSendPushNotification()}
        onCancel={() => setIsConfirmPopup(false)}
        customButtons={
          <React.Fragment>
            <Button secondary onClick={() => setIsConfirmPopup(false)}>
              No
            </Button>
            <Button primary onClick={() => handleSendPushNotification()}>
              Yes
            </Button>
          </React.Fragment>
        }
      ></SweetAlert>
    );
  };

  const handleSuccessPopup = () => {
    return (
      <SweetAlert
        success
        title="Good job!"
        onConfirm={() => setIsSuccessNotifiedPopup(false)}
        customButtons={
          <React.Fragment>
            <Button primary onClick={() => setIsSuccessNotifiedPopup(false)}>
              Ok
            </Button>
          </React.Fragment>
        }
      >
        {`Notification sent to a total of ${sentNotificationCount} ${selectedCategory} ${selectedUserType}`}
      </SweetAlert>
    );
  };

  const handleSendPushNotification = async (nxtToken) => {
    setIsNotificaitonSending(true);
    const notificationBody = {
      userType:
        selectedUserType === "Clients"
          ? "customers"
          : selectedUserType?.toLocaleLowerCase(),
      category: selectedCategory?.toLocaleUpperCase(),
      nextToken: nxtToken,
      data: {
        title: notificationTitle,
        body: message,
        deeplink: selectedDeepLinkOption.value,
      },
    };
    const url = `${API_GATEWAY}prod/admin-push-notifications`;
    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    return axios({
      method: "POST",
      url,
      timeout: 90000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
      data: notificationBody,
    })
      .then((response) => {
        setIsConfirmPopup(false);
        if (response.data?.success) {
          setSentNotificationCount(
            (prevState) => prevState + response?.data?.data.items
          );
          if (response?.data?.data?.nextToken) {
            handleSendPushNotification(response?.data?.data?.nextToken);
          }
          if (!response?.data?.data?.nextToken) {
            setIsNotificaitonSending(false);
            setIsSuccessNotifiedPopup(true);
          }
        }
        return response.data;
      })
      .catch((error) => {
        console.log("Error retrieving report data", error);
        setIsNotificaitonSending(false);
        setIsSuccessNotifiedPopup(false);
      });
  };

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    {
      key: "sendPushNotifications",
      content: "Send Push Notifications",
      active: true,
    },
  ];

  return (
    <div className="push-notification-layout">
      <BaseLayout className="loader">
        {isConfirmPopup && handleConfirmPopup()}
        {isSuccessNotifiedPopup && handleSuccessPopup()}
        <Grid>
          <div className="mt-15">{BreadcrumbExampleShorthand(breadCrumbs)}</div>
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">Send Push Notifications</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className="border-end" width={4}>
              <div className="user-filter-section mb-30">
                <span className="notifications-label">Select user type</span>
                <div className="select-dropdown">
                  <DropdownList
                    options={userOptions}
                    placeholder="Select user"
                    value={selectedUserType}
                    onChange={(event, { value }) => {
                      event.preventDefault();
                      setSelectedUserType(value);
                      if (!selectedCategory) {
                        setSelectedCategory("Active");
                      }
                    }}
                  />
                </div>
              </div>
              <div className="category-filter-section mb-30">
                <span className="notifications-label">Select a category</span>

                <div className="select-dropdown">
                  <DropdownList
                    options={categoryOptions}
                    placeholder="Select category"
                    value={selectedCategory}
                    onChange={(event, { value }) => {
                      event.preventDefault();
                      setSelectedCategory(value);
                      if (!selectedUserType) {
                        setSelectedUserType("Maids");
                      }
                    }}
                  />
                </div>
              </div>
            </Grid.Column>

            <Grid.Column width={5}>
              <div className="title-input-section mb-30">
                <label className="notifications-label">
                  Title <span>{`(only for Android) 65 chars max`}</span>
                </label>
                <Input
                  fluid
                  placeholder="Type your title here"
                  onChange={(event, data) => {
                    event.preventDefault();
                    setNotificationTitle(data.value);
                  }}
                />
              </div>
              <div className="message-input-section mb-30">
                <label className="notifications-label">
                  Message <span>178 chars max</span>
                </label>
                <Form.Field
                  rows={8}
                  control={TextArea}
                  placeholder="Tell us more about you..."
                  maxlength="178"
                  onChange={(event, data) => {
                    event.preventDefault();
                    setMessage(data.value);
                  }}
                />
              </div>
              <div className="deeplink-filter-section mb-30">
                <label className="notifications-label">
                  Deeplink to screen
                </label>

                <CustomDropdown
                  setSelectedDeepLinkOption={setSelectedDeepLinkOption}
                  selectedDeepLinkOption={selectedDeepLinkOption}
                  selectedUserType={selectedUserType}
                />
              </div>
              <div className="send-notification-button">
                <Button
                  color={!isBtnDisabled && !isNotificaitonSending && "green"}
                  className="ui success button"
                  onClick={() => setIsConfirmPopup(true)}
                  disabled={isBtnDisabled || isNotificaitonSending}
                  loading={isNotificaitonSending}
                >
                  {}
                  {`${
                    isNotificaitonSending
                      ? `Notification Sending to ${sentNotificationCount} ${selectedCategory} ${selectedUserType}`
                      : `Send Push Notification to ${selectedUserType}`
                  }`}
                </Button>
                {isNotificaitonSending && (
                  <Label basic color="red" pointing>
                    Please do not perform any action while the notification is
                    being sent.
                  </Label>
                )}
              </div>
            </Grid.Column>

            <Grid.Column width={5}>
              <div className="tag-notification">
                <Icon name="info circle"></Icon>
                <p>
                  Tags you can use in your notification:
                  <span>{`{{ first_name }}, {{ last_name }}`}</span>
                </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </BaseLayout>
    </div>
  );
};

export default PushNotifications;
