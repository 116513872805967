import React, { useState, useEffect } from "react";
import { withAuthenticator } from "aws-amplify-react";
import { Button, Header, Table, Grid, Message, Icon } from "semantic-ui-react";
import BaseLayout from "../../components/templates/BaseLayout";
import { getMonthlyRpt1 } from "../../services/Reports";
import DropdownList from "../../components/atoms/DropdownList";

import "./styles.css";
import { BreadcrumbExampleShorthand } from "../../components/molecules/functions";

const moment = require("moment");

const MonthlyRptView = () => {
  const [fromMonth, setFromMonth] = useState(0);
  const [toMonth, setToMonth] = useState(0);
  const [rptYear, setRptYear] = useState(0);
  const [rptLoading, setRptLoading] = useState(false);
  const [rptData, setRptData] = useState([]);
  const [rptRebuild, setRptRebuild] = useState("Fetch");
  const [formFailed, setFormFailed] = useState(false);

  const optYears = [];
  const currYear = new Date().getFullYear();

  for (let yr = 2020; yr <= currYear; yr++) {
    optYears.push({ key: yr, text: yr, value: yr });
  }

  const optMonths = [];

  for (let i = 0; i < 12; i++) {
    const monthName = moment(new Date(2000, i, 1)).format("MMM");
    optMonths.push({ key: i, text: monthName, value: i + 1 });
  }

  const breadCrumbs = [
    { key: "home", content: "Home", link: true, path: "/" },
    {
      key: "reports",
      content: "Reports",
      active: false,
    },
    {
      key: "monthlyReports",
      content: "Monthly Reports",
      active: true,
    },
  ];

  const RptView = () => {
    return (
      <BaseLayout>
        <Grid>
        <div className="mt-15 mb-20">
          {BreadcrumbExampleShorthand(breadCrumbs)}
        </div>
          <Grid.Row columns={5}>
            <Grid.Column verticalAlign="middle">
              <Header as="h2">Monthly Report</Header>
            </Grid.Column>
            <Grid.Column className="dropDown">
              <div>Year</div>
              <DropdownList
                options={optYears}
                value={rptYear}
                onChange={(event, { value }) => {
                  event.preventDefault();
                  setRptYear(value);
                }}
              />
            </Grid.Column>
            <Grid.Column className="dropDown">
              <div>Month From</div>
              <DropdownList
                options={optMonths}
                value={fromMonth}
                onChange={(event, { value }) => {
                  event.preventDefault();
                  setFromMonth(value);
                }}
              />
            </Grid.Column>
            <Grid.Column className="dropDown">
              <div>Month To</div>
              <DropdownList
                options={optMonths}
                value={toMonth}
                onChange={(event, { value }) => {
                  event.preventDefault();
                  setToMonth(value);
                }}
              />
            </Grid.Column>
            <Grid.Column verticalAlign="middle">
              <Button
                color="green"
                disabled={rptLoading}
                onClick={() => {
                  if (rptLoading === false) {
                    fetchData(rptYear, fromMonth, toMonth, rptRebuild);
                  }
                }}
              >
                {rptLoading ? "Loading..." : "Refresh"}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Message
          floating
          negative
          hidden={!formFailed}
          header="Something went wrong"
          content="From month cannot be greater than to month"
        />
        {rptLoading ? (
          <Message icon>
            <Icon name="circle notched" loading />
            <Message.Content>
              <Message.Header>Just one second</Message.Header>
              Processing your request.
            </Message.Content>
          </Message>
        ) : (
          <TableView rptData={rptData} />
        )}
      </BaseLayout>
    );
  };

  const TableView = ({ rptData }) =>
    rptData?.length > 0 ? (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Month</Table.HeaderCell>
            <Table.HeaderCell>New Maids</Table.HeaderCell>
            <Table.HeaderCell>New Customers</Table.HeaderCell>
            <Table.HeaderCell>Enq. Count</Table.HeaderCell>
            <Table.HeaderCell>Resp. Count</Table.HeaderCell>
            <Table.HeaderCell>Suppport Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <RowContent rptData={rptData} />
      </Table>
    ) : (
      !formFailed && <h4 className="text-danger">No matching records found</h4>
    );

  const RowContent = ({ rptData }) =>
    rptData.length > 0 ? (
      <Table.Body>
        {rptData.map((rptRow) => {
          return (
            <Table.Row key={rptRow.Id}>
              <Table.Cell>{rptRow.crMonthName ?? ""}</Table.Cell>
              <Table.Cell>{rptRow.maidsCreated ?? 0}</Table.Cell>
              <Table.Cell>{rptRow.customersCreated ?? 0}</Table.Cell>
              <Table.Cell>{rptRow.enquiryCount ?? 0}</Table.Cell>
              <Table.Cell>{rptRow.responseCount ?? 0}</Table.Cell>
              <Table.Cell>{rptRow.supportConvCount ?? 0}</Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    ) : null;

  const fetchData = (pYear, pFromMonth, pToMonth, pRebuild) => {
    setRptLoading(true);
    if (pFromMonth > pToMonth) {
      setFormFailed(true);
      setRptData([]);
      setTimeout(() => {
        setFormFailed(false);
      }, 8000);
      setTimeout(() => {
        setRptLoading(false);
      }, 2000);
    } else {
      getMonthlyRpt1(pYear, pFromMonth, pToMonth, pRebuild, "").then(
        (resData) => {
          setRptLoading(false);
          setRptData(resData);
        }
      );
    }
  };

  useEffect(() => {
    const dtToday = new Date();
    dtToday.setDate(1);
    dtToday.setHours(-1);

    setRptYear(dtToday.getFullYear());
    setFromMonth(1);
    setToMonth(dtToday.getMonth() + 1);
    setRptLoading(true);
    setRptRebuild("Fetch");
    fetchData(dtToday.getFullYear(), 1, dtToday.getMonth() + 1, "Fetch");

    return () => {};
  }, []);

  return <RptView />;
};

const MonthlyRpt = () => {
  return <MonthlyRptView />;
};

export default withAuthenticator(MonthlyRpt);
