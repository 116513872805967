/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withAuthenticator, Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { Button, Header, Icon, Table, Grid, Message } from 'semantic-ui-react';
import BaseLayout from '../../components/templates/BaseLayout';
import { qrMaidSearchesByCreatedFromCreatedAtIndex } from '../../graphql/queries';
import './styles.css';
import { BreadcrumbExampleShorthand } from '../../components/molecules/functions';

const moment = require('moment');

const Searches = ({ location, history }) => {
	const pagingFromStorage = localStorage.getItem('pagingsrch');
	const isFirstPage = location.search === '?p=1';
	const [paging, setPaging] = useState(
		pagingFromStorage && !isFirstPage
			? JSON.parse(pagingFromStorage)
			: { tokens: [], prev: null, next: null }
	);

	const Paging = ({ nextToken, tokens }) => {
		return (
			<Button.Group>
				{tokens.length > 1 && (
					<Button
						icon
						labelPosition="left"
						onClick={() => {
							tokens.splice(0, tokens.length);
							setPaging({
								tokens,
								prev: null,
								next: null
							});
						}}
					>
						First
						<Icon name="fast backward" />
					</Button>
				)}
				{paging.next && (
					<Button
						icon
						labelPosition="left"
						onClick={() => {
							tokens.pop();
							setPaging({
								tokens,
								prev: tokens[tokens.length - 1],
								next: paging.prev
							});
							history.push({ pathname: `${window.location.pathname}`, search: '' });
						}}
					>
						Prev
						<Icon name="left arrow" />
					</Button>
				)}
				{nextToken && (
					<Button
						icon
						labelPosition="right"
						onClick={() => {
							tokens.push(nextToken);
							setPaging({
								tokens,
								prev: paging.next,
								next: nextToken
							});
							history.push({ pathname: `${window.location.pathname}`, search: '' });
						}}
					>
						Next
						<Icon name="right arrow" />
					</Button>
				)}
			</Button.Group>
		);
	};

	const TableView = ({ result }) => {
		const { items: searches, nextToken } = result;

		const { tokens } = paging;

		return (
			<>
				<Paging nextToken={nextToken} tokens={tokens} />
				<Table celled collapsing>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell width={2}>Date Time</Table.HeaderCell>
							<Table.HeaderCell width={2}>User Name</Table.HeaderCell>
							<Table.HeaderCell>Type</Table.HeaderCell>
							<Table.HeaderCell width={2}>Property</Table.HeaderCell>
							<Table.HeaderCell>PostCode</Table.HeaderCell>
							<Table.HeaderCell>Bedrooms</Table.HeaderCell>
							<Table.HeaderCell>Bathrooms</Table.HeaderCell>
							<Table.HeaderCell>Freequency</Table.HeaderCell>
							<Table.HeaderCell>Maid Count</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<RowContent searches={searches} />
				</Table>
				<Paging nextToken={nextToken} tokens={tokens} />
			</>
		);
	};

	const RowContent = ({ searches }) => (
		<Table.Body>
			{searches.map(searchItem => {
				const { user } = searchItem;
				const userName = user ? user.firstName : 'Anonymous';

				const dateCreated = moment(searchItem.createdAt, 'x').format('DD/MM/YYYY HH:mm');
				return (
					<Table.Row key={searchItem.userId + searchItem.createdAt}>
						<Table.Cell>{dateCreated || ''}</Table.Cell>
						<Table.Cell>
							{userName !== 'Anonymous' ? (
								<Link to={`/customer/${searchItem.userId}?url=/searches?p=1&name=Searches`}>{userName || ''}</Link>
							) : (
								userName
							)}
						</Table.Cell>
						<Table.Cell>{searchItem.cleanType || ''}</Table.Cell>
						<Table.Cell>{searchItem.property || ''}</Table.Cell>
						<Table.Cell>{searchItem.postcode || ''}</Table.Cell>
						<Table.Cell>{searchItem.bedroom || ''}</Table.Cell>
						<Table.Cell>{searchItem.bathroom || ''}</Table.Cell>
						<Table.Cell>{searchItem.frequency || ''}</Table.Cell>
						<Table.Cell>{searchItem.resultCount || ''}</Table.Cell>
					</Table.Row>
				);
			})}
		</Table.Body>
	);

	const AllSearches = () => {
		const { next } = paging;
		const limit = 20;
		localStorage.setItem('pagingsrch', JSON.stringify(paging));
		return (
			<Connect
				query={graphqlOperation(qrMaidSearchesByCreatedFromCreatedAtIndex, {
					first: limit,
					after: next || null
				})}
			>
				{({ data: { queryMaidSearchesByCreatedFromCreatedAtIndex }, loading }) => {
					if (loading || !queryMaidSearchesByCreatedFromCreatedAtIndex)
						return (
							<Message icon>
								<Icon name="circle notched" loading />
								<Message.Content>
									<Message.Header>Just one second</Message.Header>
									Loading...
								</Message.Content>
							</Message>
						);

					const { items } = queryMaidSearchesByCreatedFromCreatedAtIndex;
					return (
						<>
							<Grid>
								<Grid.Row>
									<Grid.Column>
										<Header as="h3" color="grey">
											Displaying {items.length} searches
										</Header>
									</Grid.Column>
								</Grid.Row>
								<Grid.Row />
							</Grid>
							<TableView result={queryMaidSearchesByCreatedFromCreatedAtIndex} />
						</>
					);
				}}
			</Connect>
		);
	};

	const breadCrumbs = [
		{ key: "home", content: "Home", link: true, path: "/" },
		{
		  key: "searches",
		  content: "Searches",
		  active: true,
		},
	  ];

	return (
		<BaseLayout>
			<Grid divided>
			<div className="mt-15">
          {BreadcrumbExampleShorthand(breadCrumbs)}
        </div>
				<Grid.Row>
					<Grid.Column>
						<Header as="h2">Searches</Header>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<AllSearches />
		</BaseLayout>
	);
};

export default withAuthenticator(Searches);
