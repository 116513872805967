import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { Greetings } from "aws-amplify-react";
import {
  Header,
  Image,
  Menu,
  Dropdown,
  Label,
  Icon,
  MenuItem,
  Modal,
  Button,
} from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";

class CustomGreetings extends Greetings {
  state = {
    modalOpen: false,
  };

  handleOpenModal = () => this.setState({ modalOpen: true });
  handleCloseModal = () => this.setState({ modalOpen: false });

  confirmLogout = () => {
    Auth.signOut()
      .then((data) => {
        console.log(data);
        this.handleCloseModal();
      })
      .catch((err) => {
        console.log(err);
        this.handleCloseModal();
      });
  };

  render() {
    let email = "";
    let fullName = "";

    if (Auth.user) {
      if (Auth.user.attributes) {
        email = Auth.user.attributes.email;
        fullName = `${Auth.user.attributes.family_name} ${Auth.user.attributes.given_name}`;
      } else if (Auth.user.challengeParam) {
        email = Auth.user.challengeParam.userAttributes.email;
      }
    }

    if (!email) return null;
    const message = `Hello, ${fullName ?? email}`;

    return (
      <>
        <Header as="h1">
          <Image circular src="/images/logo.jpg" /> Maid Admin
        </Header>
        <Menu pointing secondary>
          <Menu.Item as={Link} to="/" name="home">
            Home
          </Menu.Item>
          <Dropdown item text="Maids">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/maids?p=1" name="allMaids">
                All Maids
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/blocked?p=1" name="blockedMaids">
                Blocked Maids
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/profileChanges"
                name="profileChanges"
              >
                Profile Changes
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text="Customers">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/customers" name="customers">
                All Customers
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/enquiries?p=1" name="enquiries">
                Enquiries
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/feedbacks?p=1" name="feedbacks">
                Feedbacks
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/searches?p=1" name="searches">
                Searches
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item as={Link} to="/prospects" name="prospects">
            Prospects
          </Menu.Item>
          <Menu.Item as={Link} to="/supportchats" name="supportchats">
            Support Chats
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/pushNotifications?p=1"
            name="pushnotifications"
          >
            Push Notifications
          </Menu.Item>
          <Dropdown item text="On Map">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/mapmaids" name="mapmaids">
                Maids
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/mapprospects" name="mapprospects">
                Prospects
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item text="Reports">
            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/monthlyrpt1" name="mapmaids">
                Monthly Report
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Menu.Menu position="right">
            <MenuItem>
              <div>
                <Label size="medium">
                  <Icon name="user" />
                  {message}
                </Label>
                <Label
                  size="medium"
                  onClick={this.handleOpenModal}
                  className="sortableHeaderCell"
                >
                  <Icon name="lock" />
                  Logout
                </Label>
              </div>
            </MenuItem>
          </Menu.Menu>
        </Menu>

        {/* Confirmation Modal */}
        <Modal
          size="small"
          open={this.state.modalOpen}
          onClose={this.handleCloseModal}
        >
          <Modal.Header>Confirm Logout</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to logout?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={this.handleCloseModal}>
              No
            </Button>
            <Button positive onClick={this.confirmLogout}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default withRouter(CustomGreetings);
