import React from "react";
import { List, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import { allMessageConnection } from "../../../graphql/queries";

const conversationStyle = {
  borderBottom: "1px solid black",
  padding: "5px 0",
};

const iconStyle = {
  marginLeft: "2px",
};

const repliedStyle = {
  opacity: 0.4,
};

const ConversationsList = ({ conversations, customer, maid }) => (
  <List selection verticalAlign="middle" style={conversationStyle}>
    {conversations.map((conversation) => {
      if (
        !conversation.associated ||
        conversation.associated.length === 0 ||
        !conversation.associated[0].user
      ) {
        return null;
      }

      const {
        firstName,
        lastName,
        cognitoId,
      } = conversation.associated[0].user;
      const { conversationId } = conversation;

      return (
        <List.Item key={cognitoId}>
          <List.Content>
            <Connect
              query={graphqlOperation(allMessageConnection, { conversationId })}
            >
              {({ data: { allMessageConnection }, loading }) => {
                if (loading || !allMessageConnection)
                  return (
                    <List.Header
                      as={Link}
                      to={`/conversation/${conversationId}`}
                    >
                      {firstName} {lastName}
                    </List.Header>
                  );
                if (
                  customer &&
                  allMessageConnection.messages &&
                  allMessageConnection.messages.filter((msg) => {
                    return (
                      msg.author &&
                      msg.author.cognitoId &&
                      msg.author.cognitoId !== customer &&
                      msg.author.userType !== "Admin"
                    );
                  }).length > 0
                )
                  return (
                    <List.Header
                      as={Link}
                      to={`/conversation/${conversationId}?s=1&url=/customer/${customer}&name=Customer`}
                      style={repliedStyle}
                    >
                      {firstName} {lastName}{" "}
                      <Icon name="reply" size="small" style={iconStyle} />
                    </List.Header>
                  );
                if (
                  maid &&
                  allMessageConnection.messages &&
                  allMessageConnection.messages.filter((msg) => {
                    return (
                      msg.author &&
                      msg.author.cognitoId &&
                      msg.author.cognitoId === maid
                    );
                  }).length > 0
                )
                  return (
                    <List.Header
                      as={Link}
                      to={`/conversation/${conversationId}`}
                      style={repliedStyle}
                    >
                      {firstName} {lastName}{" "}
                      <Icon name="reply" size="small" style={iconStyle} />
                    </List.Header>
                  );
                return (
                  <List.Header
                    as={Link}
                    to={`/conversation/${conversationId}?s=1&url=/customer/${customer}&name=Customer`}
                  >
                    {firstName} {lastName}{" "}
                  </List.Header>
                );
              }}
            </Connect>
          </List.Content>
        </List.Item>
      );
    })}
  </List>
);

export default ConversationsList;
