/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allMessage = `query AllMessage($after: String, $conversationId: ID!, $first: Int) {
  allMessage(after: $after, conversationId: $conversationId, first: $first) {
    author {
      cognitoId
      id
      username
      registered
    }
    content
    conversationId
    createdAt
    id
    isSent
    recipient {
      cognitoId
      id
      username
      registered
    }
    sender
  }
}
`;
export const allMessageConnection = `query AllMessageConnection($after: String, $conversationId: ID!, $first: Int) {
  allMessageConnection(
    after: $after
    conversationId: $conversationId
    first: $first
  ) {
    messages {
      content
      image
      conversationId
      createdAt
      id
      isSent
      sender
      author {
        firstName
        lastName
        userType
        cognitoId
      }
    }
    nextToken
  }
}
`;

export const allSupportConversations = `query ListAllMaidSupportConversations {
  listAllMaidSupportConversations {
    name
    id
    createdAt
    user {
      cognitoId
      firstName
      lastName
      email
      userType
    }
    messageCount
    responded
    messages {
      id
      sender
    }
  }
}`;

export const allConversations = `query AllConversations($after: String, $userId: ID!, $first: Int) {
  allConversations(
    after: $after
    userId: $userId
    first: $first
  ) {
    userId
  }
}
`;
export const allMessageFrom = `query AllMessageFrom(
  $after: String
  $conversationId: ID!
  $first: Int
  $sender: String!
) {
  allMessageFrom(
    after: $after
    conversationId: $conversationId
    first: $first
    sender: $sender
  ) {
    author {
      cognitoId
      id
      username
      registered
    }
    content
    conversationId
    createdAt
    id
    isSent
    recipient {
      cognitoId
      id
      username
      registered
    }
    sender
  }
}
`;

export const listMaids = `query ListMaids( 
  $filter: TableMaidFilterInput
  $limit: Int
  $nextToken: String
) {
  listMaids(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    hashKey
    userId
    name
    geohash
    bio
    address
    geoJson
    rate
    status
    email
    dateRegistered
  }
}`;

export const listMaidsByLocale = `query ListMaidsByLocale( 
  $useIndex: String
  $scanIndexForward: Boolean
  $locale: String! 
  $limit: Int
  $filter: TableMaidFilterInput
  $nextToken: String) {
  listMaidsByLocale(
    useIndex: $useIndex
    scanIndexForward: $scanIndexForward
    locale: $locale    
    limit: $limit
    filter: $filter
    nextToken: $nextToken) {
    items {
      conversations {
        userConversations {
          conversationId
          isSupport
        }
      }
      hashKey
      userId
      name
      companyName
      geohash
      bio
      boost
      address
      locale
      image
      imageStatus
      geoJson
      offerTrial
      rate
      status
      email
      dateRegistered
      lastUpdatedDate
      contacts
      responses
      unresponses
    }
    nextToken
    scannedCount
  }
}`;

export const listMaidsByLocaleAndStatus = `query listMaidsByLocaleAndStatus( 
  $useIndex: String
  $scanIndexForward: Boolean
  $locale: String!
  $status: String
  $limit: Int
  $filter: TableMaidFilterInput
  $nextToken: String) {
    listMaidsByLocaleAndStatus(
    useIndex: $useIndex
    scanIndexForward: $scanIndexForward
    locale: $locale
    status: $status
    limit: $limit
    filter: $filter
    nextToken: $nextToken) {
    items {
      conversations {
        userConversations {
          conversationId
          isSupport
        }
      }
      hashKey
      userId
      name
      companyName
      geohash
      bio
      boost
      address
      locale
      image
      imageStatus
      geoJson
      offerTrial
      rate
      status
      email
      dateRegistered
      lastUpdatedDate
      contacts
      responses
      unresponses
    }
    nextToken
    scannedCount
  }
}`;

export const countMaids = `query ListMaids(
  $filter: TableMaidFilterInput
  $limit: Int
  $nextToken: String
) {
  listMaids(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    status
  }
}`;

export const listUsers = `query ListUsers($limit: Int, $useIndex: String, $scanIndexForward: Boolean, $userType: String, $searchText: String, $searchCity: String, $nextToken: String) {
  listUsers(limit: $limit, useIndex: $useIndex, scanIndexForward: $scanIndexForward, userType: $userType, searchText: $searchText, searchCity: $searchCity, nextToken: $nextToken) {
    items {
      cognitoId
      firstName
      lastName
      phone
      email
      userType
      dateCreated
      locale
      contacts
      responses
      unresponses
      conversations {
        userConversations {
          conversationId
          isSupport
        }
      }
    }
    nextToken
  }
}`;

export const countCustomers = `query ListUsers($userType: String) {
  listUsers(userType: $userType) {
    cognitoId
  }
}`;

export const countProspects = `query ListProspects {
  listProspects(limit: 10000) {
    items {
      id
    }
  }
}`;

export const listAllConversations = `query ListAllConversations {
  listAllConversations {
    id
  }
}`;

export const listAllMaidSupportConversations = `query ListAllMaidSupportConversations {
  listAllMaidSupportConversations {
    id
  }
}`;

export const getConversationUsers = `query GetUsersForConvo($conversationId: ID!) {
  getUsersForConversation(conversationId: $conversationId) {
    conversationId
    user {
      cognitoId
      firstName
      lastName
      userType
    }
  }
}`;

export const getMaidContactsAndActive = `query getMaidContactsAndActive($userId: String!, $userType: String!) {
  getMaidContactsAndActive(userId: $userId, userType: $userType){
    success
    contacts
    lastActive
  }
}`;

export const getMaidReviews = `query ListUsersReviews($maidId: String!){
    listUsersReviews(filter: { maidId: { eq: $maidId } }) {
        nextToken
        items {
            user {
                cognitoId
                firstName
                lastName
                userType
            }
            content
            id
            rating
            authorName
            dateCreated
        }
    }
}`;
export const customerPostedReviews = `query ListUsersReviews($userId: String!){
    listUsersReviews(filter: { userId: { eq: $userId } }) {
        nextToken
        items {
            maid {
              name
              fullName
              userId
            }
            content
            id
            rating
            authorName
            dateCreated
        }
    }
}`;

export const getMaid = `query GetMaid($userId: String!) {
  getMaid(userId: $userId) {
    hashKey
    rangeKey
    userId
    gallery
    availability
    companyName
    businessType
    selectedDeep
    extraServices
    dateRegistered
    name
    email
    geohash
    activeSince
    bio
    boost
    address
    distance
    image
    imageStatus
    geoJson
    rate
    status
    dbsCheck
    hasInsurance
    offerTrial
    DBSFiles
    ownEquipment
    oneTimeMailSend
    user {
      phone
    }
    conversations {
      userConversations {
        conversationId
        associated {
          conversationId
          user {
            firstName
            lastName
            cognitoId
          }
        }
      }
    }
    enquiries
  }
}`;

export const getUserById = `query GetUserById($cognitoId: String!) {
  getUserById(cognitoId: $cognitoId) {
    cognitoId
    firstName
    lastName
    email
    phone
    userType
    dateCreated
    conversations {
      userConversations {
        associated {
          conversationId
          user {
            firstName
            lastName
            cognitoId
          }
        }
        conversationId
        userId
      }
    }
    status
  }
}`;

export const userConversations = `query UserConversations {
  userConversations {
    userId
    conversation {
      name
    }
    conversationId
    associated {
      userId
    }
  }
}
`;

export const prospectsByLocale = `query ListProspectsByLocale($locale: String!) {
  listProspectsByLocale(locale: $locale) {
    id
    locale
    postcode
    number
    dateCreated
    geoJson
    cleanType
    frequency
    bathroom
    bedroom
    property
  }
}`;

export const getPushToken = `query GetPushToken($userId: String!) {
  getPushToken(userId: $userId) {
    userId
    tokenId
  }
}`;

export const listDashboard = `query ListDashboard {
  listDashboards {
    items {
      ItemName
      ItemVal
    }
  }
}
`;

export const userEnquiriesByDate = `query userEnquiriesByDate ($first: Int, $after: String){
  userEnquiriesByDate (
    first: $first,
    after: $after   
  ) {
    items {
      id
      createdAt
      maidId
      maid {
        fullName,
        name
      }
      userId
      user {
        firstName
        lastName
      }
      lastMessage {
        content,
        image
      }
    }
    nextToken
  }
}
`;

export const qrUserConversationsPage = `query 
  userConversationsPage ($after: String, $first: Int,  $userId: String!) {
    userConversationsPage(after: $after,first: $first, userId: $userId) {
      userConversations {
        conversationId
        isSupport        
        associated {
          user {
            firstName
            lastName
            cognitoId
            messages(first: 1) {
              messages {
                sender
              }
            }
          }
          conversation {
            createdAt
          }
        }
      }
      nextToken
    }
  }`;

export const qrMaidSearchesByCreatedFromCreatedAtIndex = `query MyQuery($createdFrom: String = "APP", $after: String, $first: Int) {
    queryMaidSearchesByCreatedFromCreatedAtIndex(createdFrom: $createdFrom, after: $after, first: $first) {
      items {
        bathroom
        bedroom
        cleanType
        createdAt
        createdFrom
        frequency
        latitude
        longitude
        maids
        postcode
        property
        radius
        resultCount
        searchSerial
        userId
        user {
          firstName
          lastName
        }
      }
      nextToken
    }
  }`;

// export const qrFeedbackByApprStatusCreatedAtIndex = `query queryFeedbackByApprStatusCreatedAtIndex ($apprStatus: String!, $after: String, $first: Int) {
//     queryFeedbackByApprStatusCreatedAtIndex(apprStatus: $apprStatus, after: $after, first: $first) {
//       items {
//         apprStatus
//         createdAt
//         maidId
//         userId
//         convId
//         rating
//         comment
//         user {
//           firstName
//           lastName
//         }
//         maid {
//           name
//           fullName
//         }
//       }
//     }
//   }`;

export const getUsersReviewsQuery = `query ListUsersReviews($limit: Int, $nextToken: String) {
listUsersReviews(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
            id
            content
            rating
            dateCreated
            authorName
            user {
                cognitoId
                firstName
                lastName
                userType
            }
            maid {
                userId
                fullName
                name
            }
        }
    }
}`;
export const getAllFeedbacks = `query ListUsersReviewsByDate($limit: Int, $nextToken: String) {
listUsersReviewsByDate(limit: $limit, nextToken: $nextToken) {
        nextToken
        items {
            id
            content
            rating
            dateCreated
            authorName
            user {
                cognitoId
                firstName
                lastName
                userType
            }
            maid {
                userId
                fullName
                name
            }
        }
    }
}`;

export const getCustOpenSearches = `query ListMaidSearches($userId: String, $isClose: Boolean) {
listMaidSearches(filter: { userId: { eq: $userId } isClose: { ne: $isClose } }) {
        items {
            searchSerial
            userId
            createdAt
            createdFrom
            bathroom
            bedroom
            cleanType
            frequency
            latitude
            longitude
            maids
            postcode
            property
            radius
            resultCount
            isClose
        }
    }
}`;
